@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Wix+Madefor+Display');


html {
  scroll-behavior: smooth;
}


body {

  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* dark mode override here */
  /* background: rgb(235, 235, 235); */
  background: rgb(35, 35, 35); 
  overflow-y: scroll;
  background-color: black;
  background-image: none;
  background-size: 14px;
  background-position-x: 50%;
  background-position-y: 9px;
  background-repeat: repeat;  
}

myBody {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* dark mode override here */
  /* background: rgb(235, 235, 235); */
  background: rgb(35, 35, 35); 
  overflow-y: scroll;
}

.linkText {
  font-weight:bold;
  text-decoration: underline;

  color:  rgb(61, 61, 61);
}
.linkText:hover{
  color:  rgb(0, 0, 0);

}
.linkText:visited {
  color:  rgb(71, 71, 71);
}
.linkTextDark{
  color: rgb(220, 220, 220);
}

.linkTextDark:hover{
  color: rgb(255, 255, 255);
}
.linkTextDark:visited{
  color: rgb(220, 220, 220);
}

.secretDoorPageStyle{
  background-color: black;
}

.secretDoor_downloadLinks{

  width: 100%;
  margin-top: 30px;
  display: initial;
  /* background-color: orange; */
  margin: 0px;
  padding: 0px;
  width: 50%;


}

.demoReelGroup{
  /* background-color: #25c800; Green */
  text-align: center;

}

.demoreelBG{
  /* background-color: #007bc8; Green */
  height: 90%;
  width: 90%;
  max-width: 1000px;
  text-align: center;
  display: inline-block;
}

.button {
  background-color: #007bc8; /* Green */
  border: none;
  color: white;
  margin: 8px 0;

  padding: 15px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 30%;
  font-weight:bolder;
  border: none;
border-color: transparent;
font-size: 20px;
border-radius: 0px 20px 20px 0px;
font-family: 'Raleway', sans-serif;
}

.buttonGreen {
  background-color: #15a336; /* Green */
}

.button:hover{
  background-color: #2088c8;
}


.buttonGreen:hover {
  background-color: #2bb74b; /* Green */
}

.staticPage{
  background-color: #2088c8;
  height: 100%;
}

.inputBox{
  width: 50%;
  padding: 15px 30px;
  /* margin: 8px 0; */
  box-sizing: border-box;
  margin-right: 2px;
  font-weight:bolder;
  color: rgb(255, 255, 255);
  font-size: 20px;
  background-color: #404040;
  border: none;
border-color: transparent;
border-radius: 20px 0px 0px 20px;
font-weight:bolder;
font-family: 'Raleway', sans-serif;

}
.inputBoxSquare{
  border-radius: 3px 0px 0px 3px;
  margin-right: 0px;
  color: rgb(0, 0, 0);
  background-color: #e8e8e8;

}
.buttonSquare{
  border-radius: 0px 3px 3px 0px;
  background-color: #c6c6c6;
  color: #0F0F0F;
}
.buttonSquare:hover{
  background-color: #adadad;
}


.inputBoxSquareDark{
  border-radius: 3px 0px 0px 3px;
  margin-right: 0px;
  color: rgb(255, 255, 255);
  background-color: #404040;
}



.buttonSquareDark{
  border-radius: 0px 3px 3px 0px;
  background-color: #b5b5b5;
  color: #0F0F0F;
}

.buttonSquareDark:hover{
  background-color: #dbdbdb;
}

/* ---specific - styles - start - here ---------------------------------------------- */



/*  */

/* CSS GRID */

.trippleGrid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1%;
  margin: 1% 0px;
}
.doubleGrid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1%;
  margin: 1% 0px;
}
.quadGrid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1%;
  margin: 1% 0px;
}
.singleGrid{
  line-height: 0px;
  margin: 1% 0px;
}
.roundedSquare{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  width:100%;
  border-radius: 20%;
  padding: 0px;
  margin: 0px;

}

.gameInfoContainerPortrait{
  display: grid;
  padding: 0px 0.85%;
  grid-template-columns: 2.0fr repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1.3% 0.7%;
  
}

.gameInfoContainerLandscape{
  display: grid;
  padding: 0px 0.85% 0.25%;
  grid-template-columns: 1.0fr repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1.1% 0.6%;
  
}

.gameInfoContainerLandscapeC{
    display: grid;
    padding: 0px 0.85% 0.0%;
    grid-template-columns: 4.4fr repeat(8, 1fr);
    grid-template-rows: 2fr 1fr 1fr;
    grid-gap: 1.15% 0.65%;
  }

.gamePosterImgB{
  grid-row-start: 1;
  grid-row-end: 4;

  /* background-color: crimson; */
  /* border-style: solid;
  border-width: 1px;
  border-color: white; */
}
.gamePosterImgC{
  grid-row-start: 1;
  grid-row-end: 5;

  /* background-color: crimson; */
  /* border-style: solid;
  border-width: 1px;
  border-color: white; */
}

.gamePosterImg{
  grid-row-start: 1;
  grid-row-end: 4;

  /* background-color: crimson; */
  /* border-style: solid;
  border-width: 1px;
  border-color: white; */
}

.gameImgCTopA{
  grid-column-start: 2;
  grid-column-end: 4;
}
.gameImgCTopB{
  grid-column-start: 4;
  grid-column-end: 6;
}
.gameImgCTopC{
  grid-column-start: 6;
  grid-column-end: 8;
}
.gameImgCTopD{
  grid-column-start: 8;
  grid-column-end: 10;
}

.gameImgC{
  grid-column-start: 5;
  grid-column-end:7;
  grid-row-start: 2;
  grid-row-end: 4;
  /* grid-row-end: 4; */
  /* border-style: solid;
  border-width: 1px;
  background-color: blueviolet;
  color: white;
  border-color: white; */
}


.gameImgCHoriz{
  grid-column-start: 2;
  grid-column-end: 5;  
  /* border-style: solid;
  border-width: 1px;
  background-color: blueviolet;
  color: white;
  border-color: white; */
}
.gameImgCHorizB{
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 2;  
  /* border-style: solid;
  border-width: 1px;
  background-color: blueviolet;
  color: white;
  border-color: white; */
}
.gameImgCHorizC{
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 3;  
  /* border-style: solid;
  border-width: 1px;
  background-color: blueviolet;
  color: white;
  border-color: white; */
}


.titleText{
  color: rgb(115, 115, 115);
}
.titleText:hover{
  /* color: rgb(59, 59, 59); */
}

.subtitleText{
  color: rgb(146, 146, 146);
  font-weight: normal;
  position: relative;
  bottom: 18px;
  font-size: 90%;
  margin: 0px;

  /* dark mode override here */
  /* opacity: 0.4; */
}

/* default invisible, hover to see content */

.secretHoverDiv{
  opacity: 7%;
  /* background-color: fuchsia; */
  height:  50px;
  /* margin-bottom: 20px; */
  width: 70%;
  text-align: center;
  display: inline-block;  
  margin-top: 10px;
  margin-bottom: 30px;
}
.secretHoverDiv:hover{
  opacity: 1;
  height:  50px;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 70%;
}

/* ------------ ----- --- --- -----------STYLES FOR SECRET DOOR PAGE ------------ ----- --- --- ---------- */

.doorIconGroupAlt{
  /* opacity: 90%; */
  width: 100px;
  /* background-color: rgb(33, 162, 237); */

  text-align: center;
  display: inline-block;  
}

.doorIconGroupNew{
  opacity: 90%;
  width: 100px;
  /* background-color: rgb(33, 162, 237); */

  text-align: center;
  display: inline-block;  
}
.doorIconGroupNew:hover{
  opacity: 50%;
  }
.doorIconGroupNew:visited{
    opacity: 100%;
}

.secretdoor_bg{
  background-color: rgb(237, 237, 237);
  position: absolute; top: 0; left: 0;
  width: 100%;
  height: 100%;
  text-align: center;

  
}

.secretdoor_fourImageDiv{
  width: 23%;
  padding: 0 1%;
}
.secretdoor_twoImageDiv{
  /* width: 48%;
  padding: 0.8% 1%; */

}


.secretdoor_footer{
  background-color: black;
  padding: 40px 0 40px;
}
.secretdoor_footerTextBox{
  color: white;
  font-weight:lighter;
  font-family: 'Raleway', sans-serif;

  padding: 20px 0 20px;
  font-size: 23px;
}
.secretdoor_footerTextBoxName{
  color: rgb(113, 113, 113);
  font-family: 'Josefin+Sans', sans-serif;

  /* font-weight:lighter; */
  letter-spacing: 1px;
  padding: 150px 0 0px;
  font-size: 15px;
}
.secretdoor_footerTextBoxName:hover, secretdoor_footerTextBoxName:visited, secretdoor_footerTextBoxName:link, secretdoor_footerTextBoxName:active
{
  text-decoration: none;
  color: rgb(131, 131, 131);
}
  
.secretdoor_header{
  background-color: black;
  width: 100%;
  height: 100%;
  
  display: inline-block;  
}
.secretdoor_headerPreview{
  background-color: black;
  width: 100%;
  height: 350px;
  
  display: inline-block;  

}



.imageGalleryContent{
  margin: 15px;
padding: 5px;
}
.imageGalleryContent:hover{
/* opacity: 50% */
  background-color: white;
}

.secretdoor_iconbox {
  padding-top: 0;
  padding-bottom: 0;
  /* background-image: linear-gradient(#E0E0E0,rgb(218, 218, 218)); */
 /* background-color: green; */
}

.secretdoor_logobox {
  /* background-image: linear-gradient(rgb(228, 228, 228),rgb(237, 237, 237)); */
    padding: 0;
padding-top: 0;
/* background-color: rgb(204, 204, 207); */

}


.logoGraphics{
  position: relative;
  top: 45%; 
  /* background-color: purple; */

}
@media (max-height: 3000px) {
  .logoGraphics{
    top: 40%; 
    /* background-color: yellow; */
  }
}
@media (max-height: 1600px) {
  .logoGraphics{
    top: 38%; 
    /* background-color: green; */
  }
}
@media (max-height: 1400px) {
  .logoGraphics{
    top: 36%; 
    /* background-color: blue; */
  }
}
@media (max-height: 1200px) {
  .logoGraphics{
    top: 33%; 
    /* background-color: yellow; */
  }
}
@media (max-height: 1000px) {
  .logoGraphics{
    top: 29%; 
    /* background-color: blue; */
  }
}
@media (max-height: 700px) {
  .logoGraphics{
    top: 22%; 
    /* background-color: orange; */

  }
}
@media (max-height: 500px) {
  .logoGraphics{
    top: 12%; 
    scale: 80%;
    /* background-color: purple; */

  }
}
@media (max-height: 340px) {
  .logoGraphics{
    top: 0%; 
    scale: 40%;

    /* background-color: pink; */

  }
}

.secretDoor_spaceBG{
  /* background-color: rgb(58, 58, 58); */
  background-image: url('space_environment.jpg');
  background-color: black;
  /* background-image: url('checkerBoard.png'); */

  background-size: 512px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: 0 0;
  /* background-repeat: no-repeat; */
  animation-name: backgroundAnimator;
  animation-duration: 140s;
  animation-iteration-count: infinite;
  background-position: 0 0;
  animation-timing-function: linear;
}
@keyframes backgroundAnimator {
  0%   {background-position: 0 0;  }
  100%  {background-position: 0 512px;  }
}

.pageBreakGraphic{
  background-color: rgb(48, 48, 48);
  height: 3px;
}
.pageBreakGraphicB{
  background-color: rgb(29, 29, 29);
  height: 3px;
}
.pageBreakGraphicC{
  background-color: rgb(16, 16, 16);
  height: 3px;
}

.secretDoorIndentedParagraphStyle{
/* background-color: pink; */
display: flex;

}

.gameplayVideoGroup{
/* background-color: plum; */
display: block;
max-width: 800px;
}

.appIconImage{
  margin-right: 30px;
  display: unset;
  border: 5px solid rgb(165, 165, 165);
  border-radius: 22%;
}


.appIconImagePortrait{
  display: none;  
  width: 50%;
  height: 50%;
  margin-bottom: 30px;
  border: 10px solid rgb(65, 65, 65);
  border-radius: 22%;
}


.secretdoor_pageDiv{
  width: 800px;
  /* background-color: fuchsia; */
  /* margin-top: 10px;
  margin-bottom: 55px; */
  display: inline-block;  
}
  
.secretdoor_pageDivDownloads{
  width: 600px;
  /* background-color: fuchsia; */
  /* margin-top: 10px;
  margin-bottom: 55px; */
  display: inline-block;  
}

@media (max-width: 1000px) {
  .secretdoor_pageDiv{
    width: 650px;   
  }
  .secretdoor_pageDivDownloads{
    width: 550px;   
  }

  .appIconImage{
    display: none;
  }
  .appIconImagePortrait{
    display: unset;
  }

}

/* .secretDoor_downloadLinks{
  margin-top: 25px;
  /* margin-bottom: 20px; */
  /* max-width: 600px; */

  /* display: inline-block;   */

  /* background-color: orange; */



.secretdoor_infoCard{
background-color: #242424

;
padding: 20px;
margin: 50px 0;

border-radius: 20px;
}


.secretdoor_infoCardMainText{
  background-color: #242424;
  padding: 40px;
  margin: 50px 0;
  border-radius: 20px;
}
.secretdoor_downloadPageCard{
  background-color: #ffffff;
  opacity: 0.78;
  padding: 1px;
  margin: 10px 0px 15px 0px;
  border-radius: 20px;
  /* max-width: 600px; */
  /* text-align: center; */

}

.secretdoor_downloadPageSkinnyCard{
  background-color: #ffffff;
  opacity: 0.78;
  padding: 1px;
  margin: 0px 0px 0px 0px;
  
  border-radius: 13px;
  /* max-width: 600px; */
  /* text-align: center; */
}
.secretdoor_downloadPageSkinnyCard:hover{
  opacity: 1;
    background-color: #ffffff;
  
  }
.secretdoor_downloadPageCard:hover{
opacity: 1;
  background-color: #ffffff;

}

.secretdoor_downloadPageText{
  color: rgb(0, 0, 0);
  text-decoration: underline;
  font-size: 24px;
  font-weight:bolder;
  margin: 40px;
  opacity: 1;
  text-align: center;
}

/* .secretdoor_downloadPageText:hover{
  color: rgb(83, 83, 83);
 
} */

.secretdoor_downloadPageSkinnyText{
  color: rgb(0, 0, 0);
  text-decoration: underline;
  font-weight:bolder;
  margin: 20px;
  font-size: 24px;
  opacity: 1;
  text-align: center;

}

.secretdoor_infoCardLink{
  background-color: #242424

;
  padding: 20px;
  margin: 50px 0;

  border-radius: 20px;
  
  }

.secretdoor_infoCardLink:hover{
  background-color: #5c5c5c;

  
  }

  .downloadIconGraphic{

    float:left;
    /* text-align: right; */
    margin: 15px;
    height: 80px;
  }
  /* .downloadIconGraphic:hover{

opacity: 50%;
  } */

.secretdoor_infoCardPaddedA{
  background-color: #242424;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 20px;
  /* margin-left: 10px; */
}

  
.secretdoor_infoCardPaddedB{
  background-color: #242424

;
  padding: 20px;
  margin-top:50px;
  margin-bottom: 50px;
  border-radius: 20px;

  /* margin-right: 10px; */
}

.secretdoor_infoCardPaddedA:hover{
  background-color: #5c5c5c;

  /* margin-left: 10px; */
}


.secretdoor_infoCardPaddedB:hover{
  background-color: #5c5c5c;


    /* margin-right: 10px; */
  }
    


.secretdoor_linkImg{
  /* opacity: 93%; */
  width:100px;
  margin-left: 15px;
  margin-right: 15px;
}
.secretdoor_linkImgWide{
  width:230px;
  /* opacity: 93%; */

  margin: 15px;
  padding-bottom: 15px;

}
.secretdoor_textBox{
  /* background-color: rgb(34, 0, 255); */
  color: rgb(233, 233, 233);
  text-align: left;
  /* font-weight:lighter; */
  margin: 10px;
  font-size: 24px;
  line-height: 28px;

  font-family: 'Raleway', sans-serif;
}

.secretdoor_textBoxTitle {
  /* background-color: rgb(34, 0, 255); */
  color: white;
  
  text-align: left;
  font-weight:bolder;
  margin-bottom: 20px;
  font-size: 24px;
  font-family: 'Raleway', sans-serif;
/* text-decoration: underline; */

}

.secretdoor_textBoxTitleCentered{
  text-align: center;


}

.secretdoordownloads_textBoxHeader {
  /* background-color: rgb(34, 0, 255); */
  color: rgb(236, 236, 236);
  text-align: center;

  /* text-align: left; */
  font-weight:bolder;

  margin-bottom: 120px;
margin-top: 80px;
  font-size: 40px;
  font-family: 'Raleway', sans-serif;
text-decoration: underline;

}
.secretdoordownloads_textBoxTitle {
  /* background-color: rgb(34, 0, 255); */
  color: rgb(233, 233, 233);
  text-align: center;

  /* text-align: left; */
  font-weight:bolder;
  margin-bottom: 5px;
  font-size: 20px;
  font-family: 'Raleway', sans-serif;
/* text-decoration: underline; */

}


.imgRoundCorners{
border-radius: 5px;

}


.secretdoor_textBoxCenter{
  text-align: center;
  color: rgb(211, 211, 211);
  font-size: 20px;
  font-family: 'Raleway', sans-serif;

  /* font-weight:bold; */
  /* margin: 0px 10px; */
  margin-top: 12px;
}
.secretdoor_textBoxCenterNoMargin{
  text-align: center;
  font-weight:bold;
  margin-top: 10px;
  font-size: 20px;

}


.secretdoor_linkText {
  /* background-color: rgb(34, 0, 255); */
  color: white;

  text-align: center;
  font-weight:bold;
  text-decoration: underline;
  font-size: 27px;
  font-family: 'Raleway', sans-serif;

  margin: 20px 0px 20px;
}





.secretdoor_landscapeVid {
  /* background-color: pink; */
  
}
.secretdoor_portraitVid {
  /* display: none;   */

  /* background-color: rgb(255, 235, 192); */
}

/* @media (orientation: portrait){ */
@media (max-width: 799px) {
  .secretdoor_pageDiv{
    /* width: unset;   */
    width: 90%;
  }
  .secretdoor_pageDivDownloads{
    /* width: unset;   */
    width: 90%;
  }
  .secretdoor_landscapeVid {
    /* display: none;     */
  }
  .secretdoor_portraitVid {    
    /* display: unset; */
  }
  .secretdoor_linkImg{
    width: 45px;
  }
  .secretdoor_linkImgWide{
    width: 125px;
  padding: 10px 0;

  }

  .secretdoor_footerTextBox{
  font-size: 20px;
  
  }

  .secretDoor_downloadLinks{

    /* width: 100%; */
    margin-top: 35px;
    display: initial;
    /* background-color: green; */
    margin: 0px;
    padding: 0px;
    width: 50%;



  }
  .secretdoor_linkText {
    font-size: 20px;
    margin: 30px 5px 25px;
    /* color: black; */
  }
  .secretdoor_textBoxCenter{
    font-size: 17px;
  }
  .secretdoor_textBox{
    font-size: 20px;
line-height: 24px;
  }

}






/* ---- ----- --- --- -- ---- ----- --- ----- ----- --- --- --- ---- ----- --- --- -- ---- ----- --- --- -- */







/* general style for whole header */
.myHeader {
  text-align: center;
  color: rgb(146, 146, 146);
}

.logoImg{
  width: 205px;
  padding: 0;
  padding-top: 15px;
  padding-bottom:  20px;
  /* dark mode override here */
  /* opacity: 0.4; */
}


.myPageTitle{
  display: inline-block;  
  height: 90px;
}
.myPageTitleBG{  

  /* height: 90px;   */
  background-color: #EBEBEB;
  /* background-color: fuchsia; */
  margin: auto;

  /* width: 720px; */
}
.myPageTitleBGDark{    
  background-color: #0F0F0F;
  /* background-color: fuchsia; */
}

.myPageTitle:hover{
  opacity: 0.8;
}




/* css for the button bar itself */
.buttonBar{
  display: grid;
  grid-template-columns: auto 18.4% 18.4% 18.4% auto;
  grid-gap: 5px;
  padding: 0px 15px;
  align-content: center;

  /* padding-top: 0px;
  padding-bottom: 12px;
  display: flex;
  /* width: 749px;  */
}

/* individual button style */
.navButton{
  /* dark mode override here */
  color: rgb(119, 119, 119);
  background-color:  rgb(217, 217, 217);

  /* width: 130px; */
  border: none;
  font-weight:bold;
  font-size: 80%;
  padding-top: 8px;
  padding-bottom: 8px;
}
.navButton a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}

.navButton:hover{
  opacity: 0.75;
}
.whiteText, .whiteText:visited{
  color: rgb(197, 197, 197);
  font-weight:bold;

}
.blackText, .blackText:visited{
  color: rgb(85, 85, 85);
  font-weight:bold;
  font-size: 20px;
}

.myGamePage{
  padding: 25px 30px 10px 30px;
  margin:  0px 15px 15px 15px;
  margin-top: 0px;


  background: rgb(250, 250, 250);

}

.selectedNavButton{
  /* dark mode override here */
  color: rgb(255, 255, 255);
  background-color:  rgb(165, 165, 165);
  font-weight: bolder;
  box-shadow: 0px 0px 0px 1px #d4d4d4;

  /* width: 130px; */
  border: none;
  font-size: 80%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.selectedNavButton:hover{
  opacity: 0.75;
}

h1{
  font-size: 25px;
  text-align: left;

  /* dark mode override here */
  color: rgb(75, 75, 75);
}

.txt{
  text-align: left;

  /* dark mode override here */
  color:  rgb(46, 46, 46);  

}

.myBody{
/* empty */
}

.aboutParagraph{
  font-size: 106%;
  /* dark mode override here */
  color: rgb(35, 35, 35);
  background: rgb(250, 250, 250);
}


.sixteenNineAspect{
  width:100%;
  /* whatever width you want */
  position: relative;
  padding-top: 56.5%;  
}


/* background div colour before content has been loaded */
.movie{
  position: absolute;
  top: 0px;
  background: rgb(0, 0, 0);
  display: block;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  border-color: rgb(0, 0, 0);
  border-width: 3px;
  border-style: solid;
}

.demoReel{
  border-radius: 0px;
  position: absolute;
  top: 0px;
  background: rgb(0, 0, 0);
  display: block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-top-width: 0px;
  border-bottom-width: 1px;
  border-color: black;
  border-style: solid;
}

.videoPreviewImg{
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
}

.videoPreviewImg_secretDoor{
  width: 100%;
  aspect-ratio: 1.7777777;
  /* background: rgb(183, 98, 98); */
  border-radius: 7px;    
}

.icon{
  width: 20px;
  height: 20px;
  position: fixed;

  top: 0;
  padding:  7px;
  opacity: 0.4;
  z-index: 100;
  display:none;
  
}

.picture{
  background: rgb(172, 172, 172);
}

.page {
  /* background: rgb(235, 235, 235); */
  text-align: center;
  position: absolute;
  top: 127px;
  left: 0;
  right: 0;
}

.myPage{
  /* display: inline-block;   */
  /* width: 615px; */
  padding: 50px;
  padding-top: 12px;
  padding-bottom: 25px;
  margin:  0px 15px 15px 15px;

  margin-bottom:  15px;

  /* dark mode override here */
  background: rgb(250, 250, 250);
}
.inlineDescription{
  display: inline-block;
  padding: 4px
}

.myArtPage{
  /* display: inline-block;   */
  /* width: 615px; */
  padding: 25px 30px 30px 30px;

  margin:  0px 15px 15px 15px;
  margin-top: 0px;
  
  /* dark mode override here */
  background: rgb(250, 250, 250);

}


.myMediaPage{
  /* display: inline-block;   */
  padding: 50px;
  padding-top: 7px;
  padding-bottom: 15px;
  /* margin:  60px; */
  margin:  0px 15px 15px 15px;
  /* dark mode override here */
  background: rgb(250, 250, 250);
}

.myAboutPage{

  padding: 30px 50px;
  /* padding-top: 20px; */
  /* padding-bottom: 30px; */
  margin:  0px 15px 15px 15px;
  margin-bottom:  0px;
  text-align: left;
  font-size: 110%;

  /* dark mode override here */
  background: rgb(250, 250, 250);
}

.myAboutPageBox{
  padding: 30px 20px;
  /* padding-top: 20px; */
  /* padding-bottom: 30px; */
  margin:  0px 15px;
  margin-top: 15px;
  margin-bottom:  0px;
  /* dark mode override here */
  background: rgb(250, 250, 250);
  text-align: left;
  font-size: 110%;
}
.allignCenter{
  text-align: center;
}

.infoBox{
  text-align: center;


  /* dark mode override here */
  background: rgb(250, 250, 250);
}

.aboutBgTint{
  margin: 0 60px;
  padding: 0px;
  align-content: center;
  text-align: center;
}

.labeledIconButton{
  margin: 0px 10px;
  width: 280px;  
  height: 75px;
  /* dark mode override here */
  background: white;
}

.socialCenter{
  display: inline-block;  
}

.socialLinkBox {
  width: 600px;
  display: inline-block;
  margin: 10px 0px 20px 0px;
}

.labeledIconButton:hover { 
  opacity: 0.75;
}

.labelIconText{

  margin-left: 70px;
  position: relative;
  bottom: 47px;
  font-weight:bold;

  /* dark mode override here */
  color: rgb(22, 22, 22);
}

.iconButton{
  width: 75px;
  height: 75px;  
  /* dark mode override here */
  background:  rgb(243, 243, 243);
}

.iconImage{
  padding: 13px;
  width: 50px;
  height: 50px;
  opacity: 0.5;
}

.img{
  display: initial;
  background: rgb(172, 172, 172);
  width: 100%;
  height: 100%;

  /* margin: 3.8px 5.8px; */

}

.portraitImg{
  object-fit: cover;
  /* display: inline-block; */
  background: rgb(172, 172, 172);
  box-sizing: border-box;
  height: 100%;
  /* height: 164px; */
  /* width: 92px; */
  width: 100%;
  /* margin: 3.8px 5.8px; */
  /* margin: 0px 1.75px; */
  /* margin: 0% 0.4%; */

}

.bigPortraitImg{
  object-fit: cover;
  height: 98.75%;
  /* display: inline-block; */
  background: rgb(172, 172, 172);
  width: 100%;
  /* height: 339px;
  width: 189px; */
  /* margin: 3.8px 5.8px; */
  /* margin: 0px 2.2px; */
  /* margin: 0% 0.4%; */

}
.bigPortraitImgB{
  object-fit: cover;
  height: 100%;
  /* display: inline-block; */
  background: rgb(172, 172, 172);
  width: 100%;
  /* height: 339px;
  width: 189px; */
  /* margin: 3.8px 5.8px; */
  /* margin: 0px 2.2px; */
  /* margin: 0% 0.4%; */

}


.landscapeImg{
  object-fit: cover;
  display: inline-block;
  background: rgb(172, 172, 172);
  height: 100%;
  width: 100%;
  /* margin: 3.8px 5.8px; */


}
.landscapeImgB{
  object-fit: cover;
  display: inline-block;
  background: rgb(172, 172, 172);
  height: 105.5px;
  width: 194px;
  margin: 3.8px 5.8px;


}
.landscapeImgC{
  object-fit: cover;
  display: inline-block;
  background: rgb(172, 172, 172);
  height: 76px;
  width: 144px;
  margin: 3.8px 5.8px;
  
}
.groupCstack{
  width: 155px;
  /* background-color: crimson; */
}

.bigLandscapeImg{
  object-fit: cover;
  display: inline-block;
  background: rgb(172, 172, 172);
  height: 171px;
  width: 293px;
  margin: 3.8px 5.8px;
}


.singleImage{
  background: rgb(172, 172, 172);

  object-fit: cover;
  width: 100%; 
  height: 100%;
  /* width: 98.2%; */

}

.voteBox{
  /* background: rgb(211, 221, 68); */
  width: 44%;
  padding:  3%;
  margin-bottom: 20px;
  display: inline-block;
}



.surveyTextBox{
  width: 97%;
  font-size: 20px;
  padding: 5px;
  margin: 10px 0px;
}

.voteImage{
  background: rgb(172, 172, 172);

  
  width: 100%; 
  height: 100%;
  /* width: 98.2%; */
}

.voteDebug{
  background: rgb(159, 159, 159);
  padding: 20px;
}

.debugSection{
  background: rgb(230, 230, 230);
  padding: 20px;

}

.debugTitle{
  color: white;
  font-weight:bold;
  text-align: center;
  font-size: 200%;
}
.debugSectionTitle{
  font-weight:bold;
  padding: 5px 0px
}

.surveySubmitButton{
font-size:  20px;
padding: 20px;
margin: 20px 0px;
width: 100%;
}
.starVoteDiv{
/* background: rgb(148, 58, 58); */
  /* margin: 0%; */
  padding: 11px 0px;
  /* text-align: center; */
}

.starButtonEmpty{
  border: none;
  background: rgb(138, 138, 138);
  height: 30px;
  width: 10%;
  background      : url('star-off.svg');
  background-size : contain;
}
.starButtonFull{
  border:none;
  background: rgb(255, 196, 0);
  height: 30px;
  width: 10%;
  background      : url('star-on.svg');
  background-size : contain;
}
.starButtonFull:hover, .starButtonEmpty:hover {
  opacity: 0.5;
}

.commentDebugText{
  font-size: 65%;
  padding: 10px 10px;
  background: white;
  margin:  10px 0px 5px;
}


.invisible{
  font-size: 0px;
}

 .starRating:not(old){
  /* background: rgb(245, 245, 241); */
  display        : inline-block;
  width          : 100%;
  height         : 1.5em;
  overflow       : hidden;
  vertical-align : bottom;
}
.starRating:not(old) > input{
  margin-right : -100%;
  opacity      : 0;
}
.starRating:not(old) > label{
  display         : block;
  float           : right;
  position        : relative;
  background      : url('star-off.svg');
  background-size : contain;
}
.starRating:not(old) > label:before{
  content         : '';
  display         : block;
  width           : 1.5em;
  height          : 1.5em;
  background      : url('star-on.svg');
  background-size : contain;
  opacity         : 0;
  transition      : opacity 0.2s linear;
}
.starRating:not(old) > label:hover:before,
.starRating:not(old) > label:hover ~ label:before,
.starRating:not(:hover) > :checked ~ label:before{
  opacity : 1;
} 

.commentBox{
  /* background: rgb(230, 230, 230); */
  width: 99%;
  margin: 10px 0px;
  height: 75px;
}

.doubleImage{
  background: rgb(172, 172, 172);

  width: 100%; 
  /* width: 48.7%; 
  margin: 0px 2px; */
 
}
.tripleImage{
  background: rgb(172, 172, 172);

  width: 100%; 
  /* margin: 0px 2px; */
 
}
.quadImage{
  background: rgb(172, 172, 172);

  width: 100%; 
  /* width: 48.7%;
  margin: 0px 2px; */

}

.textBoxTitle{
  text-align: center;
  color: rgb(211, 211, 211);
  font-size: 25px;
  font-family: 'Josefin Sans', sans-serif;

  font-weight:bold;
  /* margin: 0px 10px; */
  margin-top: 12px;
}

.textBoxLink{
  text-align: center;
  color: rgb(138, 138, 138);
  font-size: 20px;
  font-family: 'Josefin Sans', sans-serif;

  /* font-weight:bold; */
  /* margin: 0px 10px; */
  margin-top: 10px;
  margin-bottom: 5px;
}

.indent{
  margin: 30px 5px 10px;
}

.img:hover, .portraitImg:hover, .bigPortraitImg:hover, .landscapeImg:hover, .bigLandscapeImg:hover, .videoPreviewImg:hover, .infoBox:hover, .bigPortraitImgB:hover,
.singleImage:hover, .doubleImage:hover, .tripleImage:hover, .quadImage:hover, .titleBoxLink:hover, .landscapeImgC:hover, .landscapeImgB:hover, .roundedSquare:hover,
.voteImage:hover .icon:hover {
  opacity: 0.5;
}


.imgGrid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  width:100%;

  padding: 0px;
  margin: 0px;
  /*
  text-align: left; */
  /* display: inline-block; */
}

.imgGridCenter{
  text-align: center;
    /* background-color: crimson; */
  /* margin-bottom: 20px; */

}

.alignmentImg{

  width: 100%;
  height: 100%;
}

.gameGrid{
  /* background-color: crimson; */
  padding: 0px;
  margin: 0px;
  text-align: left;
  display: inline-block;
  width: 66.2%;

}

.br{
  size: 1px;
  line-height: 20px;
}

.gameImgGroup{
  display: inline-block;
  text-align: center; 
  width:100%;
  /* width: 616px;  */
  /* zoom: 0.85;
  -moz-transform: scale(0.85); */

  /* background-color: rgb(163, 20, 220); */


}

.gameImgGroupLandscape{
  display: inline-block;
  text-align: center; 

  /* background-color: rgb(163, 20, 220); */
  width: 616px;
  /* zoom: 0.85;
  -moz-transform: scale(0.85); */
}

.portraitGroupLeft{
width: 307px;
   /* background-color: rgb(220, 173, 20); */
}
.portraitGroupRight{
 width: 307px; 
  /* background-color: crimson; */
  }
.portraitGroupRightB{
    width: 620px; 
     /* background-color: crimson;    */
}
.portraitGroupRightC{
  width: 640px; 
   /* background-color: crimson;    */
}


.inlineBlock{
  display: initial;  
}

.flex{
  width: 150px;
  margin: 0px 0px 0px;
}

.flexLandscape{
  display: flex;
}

.umm{
  /* display: flex; */

  /* margin-bottom: 50px; */
  /* margin-top: 4px; */
}

.descriptionBox{

  /* margin: 0px 3px 3px; */

  margin: 1.8% 0.8%;
  /* height: 92px; */
  /* dark mode override here */
  background-color:  rgb(226, 226, 226);
}

.descriptionBoxB{

  /* margin: 0px 3px 3px; */

  margin: 1.4% 0.8%;
  /* height: 92px; */
  /* dark mode override here */
  background-color:  rgb(226, 226, 226);
}
.descriptionBoxC{

  /* margin: 0px 3px 3px; */

  margin: 2.05% 0.8%;
  /* height: 92px; */
  /* dark mode override here */
  background-color:  rgb(226, 226, 226);
}

.titleBox{
  display: inline-block;
  padding: 5px 0px;
  margin: 4px 0px;
  width: 50.6%;
  height: 25px;
   /* dark mode override here */
   background-color: rgb(226, 226, 226);
}
.titleBoxWide{
  display: inline-block;
  padding: 5px 0px;
  margin: 4px 0px;
  width: 74.3%;
  height: 25px;
   /* dark mode override here */
   background-color: rgb(226, 226, 226);
}
.titleBoxFull{
  display: inline-block;
  padding: 5px 0px;
  margin: 2px 0px;
  width: 100%;
  height: 25px;
   /* dark mode override here */
   background-color: rgb(226, 226, 226);
}

.popupModal {
  z-index: 2;
  position:fixed;
  padding:0;
  margin:0;
  text-align: center;
  top:0;
  left:0;

  width: 100%;
  height: 100%;
  background:rgba(22, 22, 22, 0.938);

  /* fade stuff */
  -webkit-animation: fadein 0.7s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.7s; /* Firefox < 16 */
   -ms-animation: fadein 0.7s; /* Internet Explorer */
    -o-animation: fadein 0.7s; /* Opera < 12.1 */
       animation: fadein 0.7s;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


.modalImg{
  position:fixed;
  padding:0;
  margin:0;

  top:0;
  left:0;

  width: 100vw;
  height: 100%;
  object-fit: contain;
  
}
.titleBoxLink{
  display: inline-block;
  padding: 5px 0px;
  /* margin: 0px 2px 0px; */
  width: 24%;
  height: 25px;
  /* dark mode override here */
  background-color:  rgb(235, 235, 235);
}


.descriptionBoxText{
  padding: 8px 10px;
  text-align: left;
  font-size: 15.5px;
    /* dark mode override here */
    color: rgb(19, 19, 19);
}
.descriptionBoxLink{
  /* display: inline-block; */
  text-align: center;
  font-weight:bold;
  text-decoration: underline;
  padding: 15px 0px 18px;
  margin: 5px 5px 15px;
  font-size: 20px;

  background-color: #1d82db;
  border-radius:99px;

}
.descriptionBoxLink:hover{
  opacity: 0.75;
}

.descriptionBoxLinkText{
  color:  rgb(255, 255, 255);
  text-decoration: underline;
}

.gameTitleBar{
  /* background-color: crimson; */
  height: 44px;
}

.gameTitle{
  font-weight:bold;
  text-align: left;
  padding-left: 13px;
  position: relative;
  bottom: -3.5px;
  /* dark mode override here */
  color:  rgb(19, 19, 19);
}

.gameLink{
  text-decoration: underline;
  position: relative;
  bottom: -2.0px;
  font-style: italic;
  /* dark mode override here */
  color: rgb(19, 19, 19);
}

.emptyButton{

  display: inline-block;
  padding: 0px 0px;
  margin: 0px 2px 0px;
  width: 0%;
  height: 0px;
}

/* fades */
.fade-appear, .fade-enter{
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 600ms;
}
.fade-exit{
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms linear;
}

@media (max-width: 772px){
  .button {
    /* font-size: 15px; */
    width: 30%;
    
    
    }
    .secretdoor_downloadPageText{
      margin: 22.5px 10px;  
    }
    .secretdoor_downloadPageSkinnyText{
      font-size: 22px;
    }


    .downloadIconGraphic{

      height: 40px;
    }

    .secretdoor_downloadPageSkinnyText{
      margin: 15px 0px;
    
    }

    .inputBox{
    /* font-size: 15px; */
    width: 65%;
    }
}

@media (min-width: 772px){
  body {
    background-image: url('dotPattern.svg');

    
  }


  
  .gameplayVideoGroup{
    /* background-color: plum; */
    display:flex;
    max-width: 800px;
  }



  .secretdoor_infoCardPaddedA{

    padding: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 20px;
    margin-left: 10px;
  }
  
    
  .secretdoor_infoCardPaddedB{

    padding: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 20px;
  
    margin-right: 10px;
  }
  



  .icon{
    display: initial;
    padding:  15px;
  }

  /* .img{   
    width: 23.2%;
    height: 23.2%;
  } */

  .socialLinkBox {
    margin: 30px 0px 0px 0px;

  }

  .gameImgGroup{
    display: inline-block;
    text-align: center; 
    margin-bottom: 0px;

    /* zoom: 1;
    -moz-transform: scale(1); */

    /* background-color: rgb(163, 20, 220);     */
  }  
  .gameImgGroupLandscape{
    display: inline-block;
    text-align: center;
    /* background-color: rgb(163, 20, 220); */
    width: 616px;
    /* zoom: 1;
    -moz-transform: scale(1); */

  }


  .myGamePage{
    width:690px;
    padding: 25px 30px 10px 30px;
    margin:  0px 15px 15px 15px;
    margin-top: 0px;

    display: inline-block;
  }  


  .buttonBar{
    width:750px;
    grid-gap: 6px;

  }
  .buttonBarCenter{
    text-align: center;
    display: inline-block;
  }
  .myMediaPage, .myPage, .myAboutPage, .myAboutPageBox{
    width:650px;
    display: inline-block;
  }
  .myArtPage{
    padding: 40px 50px 30px 50px;
    margin:  0px 15px 15px 15px;
    width:650px;
    display: inline-block;
  }
  .myAboutPageBox{
    width:708px;
  }
  

  .indent{
    margin: 30px 5px 10px;
  }
}
/* @media (max-width: 771px){
  body{
    background-color: crimson;
  }
} */

@media (max-width: 680px){
  body {
    background-image: none;  
  }
  
 .button {
    font-size: 18px;
    width: 35%;
    
    
    }
    
    .inputBox{
    font-size: 18px;
    width: 55%;
    }

  .voteBox{
    width: 100%;
  /* padding:  3%; */
  /* margin-bottom: 20px; */
  }
  .starButtonEmpty{
    /* background: rgb(148, 58, 58); */
    height: 44px;
    width: 10%;    
    }
  .starButtonFull{
    /* background: rgb(148, 58, 58); */
    height: 44px;
    width: 10%;    
    }

  


  .starRating:not(old){
    /* background: rgb(245, 245, 241); */  
    width          : 100%;
    height         : 3em;
  }
  
  .starRating:not(old) > label:before{
    width           : 3em;
    height          : 3em;
  }
  

  /* .img{
    width:22.7%;
    height:22.7%;
  } */
  .aboutParagraph{
    font-size: 85%;
  }
}
@media (max-width: 656px){
  body {
    background-image: none;  
  }

  /* .textBoxTitle{
    font-size: 20px;  
  }
  
  .textBoxLink{
    font-size: 15px;
  } */



  .socialLinkBox{
    width:300px;
    display: inline-block;
    /* margin-top: 30px;  */
  }
/* 
  .img{
    width:22.0%;
    height:22.0%;
  } */
}
@media (max-width: 542px){
  body {
    background-image: none;  
  }
  .secretdoor_downloadPageText{
    font-size: 20px;
  }
  .secretdoor_downloadPageSkinnyText{
    font-size: 20px;
  }


  /* .img{
    width:30.5%;
    height:30.5%;
  } */
  
} 
@media (max-width: 538px){
  body {
    background-image: none;  
  }




  .button {
    font-size: 16px;
    width: 35%;
    
    
    }
    
    .inputBox{
    font-size: 16px;
    width: 55%;

    padding: 15px 20px;
    }


  /* .img{
    width:29.1%;
    height:29.1%;
  } */
  .secretdoor_textBox{
    font-size: 20px;
    line-height: 24px;
  }
  .secretdoor_textBoxCenter{
    font-size: 19px;
  }
  .descriptionBoxText{
    padding: 8px 10px;

    font-size: 13.5px;
  }
  .descriptionBoxLink{
    font-size: 15px;  
  }
  .secretdoor_textBoxTitle {
    /* background-color: rgb(34, 0, 255); */

    font-size: 20px;
    /* text-decoration: underline; */
  }  
  .secretdoor_infoCardMainText{
    padding: 25px;
    
    }
    .textBoxTitle{
      font-size: 25px;  
    }  
    .textBoxLink{
      font-size: 14px;
    }
  
     
} 



@media (max-width: 450px){
  .secretdoor_downloadPageText{
    font-size: 16px;
  }
  .secretdoor_downloadPageSkinnyText{
    font-size: 18px;
  }


}


@media (max-width: 430px){
  .secretdoor_downloadPageText{
    font-size: 14px;
  }

}

@media (max-width: 420px){
  
  .secretdoor_downloadPageText{
    font-size: 13px;
  }
  .secretdoor_downloadPageSkinnyText{
    font-size: 18px;
  }

  .button {
    font-size: 15px;
    width: 45%;
    
    
    }
    
    .inputBox{
    font-size: 15px;
    width: 50%;
    }


  .secretdoor_linkText {
    /* background-color: rgb(34, 0, 255); */   
    font-size: 18px;   
  }

  body {
    background-image: none;  
  }
    .page {
      text-align: center;
      position: absolute;
      top: 127px;
      left: 0;
      right: 0;
    }
    
    .imgGrid{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 8px;
    }    

    /* .img{   
      width: 28.3%;
      height: 28.3%;
    } */
  
    .gameTitle {
      position: relative;
      padding-left: 13px;
      bottom: -6.0px;
      font-size: 10px;
    }
    
    .gameLink{
      position: relative;
      bottom: -5.7px;
      font-size: 10px;

    }

    .indent{
      margin: 10px 5px 10px;
    }

    .trippleGrid{    
      grid-gap: 0.5%; 
      margin: 0.5% 0px; 
    }
    .doubleGrid{
      display: grid;  
      grid-gap: 0.5%;  
      margin: 0.5% 0px;
    }
    .quadGrid{    
      grid-gap: 0.5%; 
      margin: 0.5% 0px; 
    }
    .singleGrid{    
      grid-gap: 0.5%; 
      margin: 0.5% 0px; 
    }
  
    .gameImgGroup{
      display: inline-block;
      text-align: center; 


      /* zoom: 0.65;
      -moz-transform: scale(0.65); */
  
      /* background-color: rgb(163, 20, 220); */    
    }  
    .gameImgGroupLandscape{
      display: inline-block;
      text-align: center;
      /* background-color: rgb(163, 20, 220); */
      width: 616px;
      /* zoom: 0.65;
      -moz-transform: scale(0.65); */
  
    }



    .socialLinkBox {
      margin: 15px 0px 0px 0px;
    }
    
    .buttonBar{
      grid-gap: 6px; 
      padding: 0px 8px;
 
    }
    .buttonBarCenter{
      /* display: inline-block; */

    }
    .iconImage{
      padding: 13px;
      width: 25px;
      height: 25px;
      opacity: 0.5;
    }

    .iconButton{
      width: 50px;
      height: 50px;  
      
    }
    .labelIconText{
      margin-left: 40px;
      position: relative;
      bottom: 34px;
      font-weight:bold;

    }

    .labeledIconButton{
      background: white;
      margin: 0px 10px;
      width: 240px;  
      height: 50px;
    }

    .myMediaPage, .myPage, .myAboutPage{
      padding: 9px 25px;
      margin:  8px;
      margin-top: 0px;
      margin-bottom:  5px;
    }
    .myGamePage{
      padding: 9px 10px 0.1px;
      margin:  8px;
      margin-top: 0px;
      margin-bottom:  5px;
      background: rgb(250, 250, 250);

    }

    .myArtPage{
      padding: 3.5px 8px 3.1px 8px;
      margin:  0px;
      margin-top: 0px;
      margin-bottom:  1px;
    }

    .aboutParagraph{
      font-size: 75%;
 
    } 
    
    .navButton{
     padding-top: 8px;
      padding-bottom: 8px;
    }    
    
    .selectedNavButton{
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .myAboutPageBox{
      padding: 30px 20px;
      /* padding-top: 20px; */
      /* padding-bottom: 30px; */

      margin:  8px;

      margin-top: 15px;
      margin-bottom:  0px;
      text-align: left;
      font-size: 110%;
    }

    .br{
      size: 0px;
      line-height: 0px;
    }

}

@media (max-width: 375px){
  .secretdoor_downloadPageText{
    font-size: 11px;
  }

  .button {
    font-size: 14px;
    width: 50%;
    
    
    }
    
    .inputBox{
    font-size: 14px;
    width: 45%;
    }

}

@media (max-width: 318px){

  .quadGrid{
    grid-template-columns: repeat(2 1fr);
  }



  .iconImage{
    padding: 7.5px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  .iconButton{
    width: 35px;
    height: 35px;  
    
  }
  .labelIconText{
    margin-left: 31px;
    bottom: 27px;
  }

  .labeledIconButton{
    width: 200px;  
    height: 35px;
  }

  .aboutParagraph{
    font-size: 75%;
  }
  .imgGrid{
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }    

} 

@media (max-width: 296px){
  /* .img{
    width:27.3%;
    height:29.3%;
  } */
  .secretdoor_downloadPageText{
    font-size: 9px;
  }
  .descriptionBoxText{
    padding: 8px 10px;

    font-size: 10.5px;
     
     
  }
  .descriptionBoxLink{
    font-size: 12px;  
  }

} 

@media (max-width: 275px){
  /* .img{
    width:90%;
    height:90%;
  } */
  .imgGrid{
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3px;
  }  

  .secretdoor_downloadPageText{
    font-size: 7px;
  }
} 

@media (max-width: 256px){
  /* .img{
    width:90%;
    height:90%;
  } */
  .imgGrid{
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3px;
  }  

  .secretdoor_downloadPageText{
    font-size: 5px;
  }
} 

/* .LightBackground{
  z-index: -1;
  position: absolute;
  padding:0;
  margin:0;
  text-align: center;
  top:0;
  left:0;
  overflow:auto;
  width: 100%;
  height: 100%;
  background: rgb(235, 235, 235);

} */
/*  */
/* dark mode stuff here */
/*  */
/* 
.DarkBackground{
  z-index: -1;
  position: absolute;
  padding:0;
  margin:0;
  text-align: center;
  top:0;
  left:0;
  overflow:auto;
  width: 100%;
  height: 100%;
  background:rgb(15,15,15);
  opacity: 0.99;
} */

.h1Dark{
  /* dark mode */
  color: rgb(202, 202, 202);
}

.txtDark{
  /* dark mode */
  color:  rgb(212, 212, 212);
}

.titleTextDark{
  color: rgb(170, 170, 170);
}



.subtitleTextDark{
  /* dark mode */

  opacity: 0.75;
}

.navButtonDark{
  /* dark mode */
color: rgb(206, 206, 206);
background-color:  rgb(40,40,40)
}

.selectedNavButtonDark{
/* dark mode */
color: rgb(255, 255, 255);
background-color:  rgb(70, 70, 70);
box-shadow: 0px 0px 0px 0.6px #525252;
}

.pageDark .BodyDark{
  background: rgb(15,15,15);  
}

.myPageDark, .myArtPageDark, .myMediaPageDark, .myAboutPageDark{
  background: rgb(40,40,40);

}


.titleBoxFullDark, .titleBoxWideDark, .titleBoxDark, .descriptionBoxDark{
  /* dark mode */
  background-color:  rgb(54, 54, 54);
}


.titleBoxLinkDark{
  /* dark mode */
  background-color:  rgb(63, 63, 63);
}

.descriptionBoxTextDark, .gameTitleDark, .descriptionBoxTextDarkLink{
    /* dark mode */
    color:  rgb(212, 212, 212);
}


.gameLinkDark{
  /* dark mode */
  color:  rgb(197, 197, 197);
}

.iconImageDark{

  opacity:0.7;
}

.aboutParagraphDark{
  /* dark mode */
  color: rgb(199, 199, 199);
  background: rgb(40,40,40); 
}

.myAboutPageBoxDark{
  /* dark mode */
  background: rgb(40,40,40);
}

.infoBoxDark{
  /* dark mode */
  background: rgb(51, 51, 51);
}

.labeledIconButtonDark{
  /* dark mode */
  background: rgb(36, 36, 36);
}

.labelIconTextDark{
  /* dark mode */
  color: rgb(223, 223, 223);
}

.iconButtonDark{
  /* dark mode */
  background:  rgb(179, 179, 179);
}


/* ---------------------------------- */


.container {
  position: relative;
  /* display: block; */
  width: 100%;
  height: 100%;
}

.pic {
  position: absolute;
  border-radius: 7px;    
  border-width: 3px;
border-color: #242424;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-animation: fade 16s infinite;
  animation: fade 16s infinite;
}
#pic1 {
  animation-delay: 0s;
  background-image: url('TrailerVideoImageA.jpg');

}
#pic2 {
  background-image: url('TrailerVideoImageB.jpg');
  animation-delay: 4s;
}
#pic3 {
  background-image: url('TrailerVideoImageC.jpg');
  animation-delay: 8s;
}
#pic4 {
  background-image: url('TrailerVideoImageD.jpg');
  animation-delay: 12s;
}
@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  28% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  28% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}